// extracted by mini-css-extract-plugin
export var SCA_container = "portfolio-module--SCA_container--415e7";
export var SCA_wrapper = "portfolio-module--SCA_wrapper--bd19d";
export var azure = "#3c86a5";
export var blanco = "#fff";
export var caption = "portfolio-module--caption--cad51";
export var cloud = "#687077";
export var columnGallery = "portfolio-module--columnGallery--0ffdf";
export var columnTop = "portfolio-module--columnTop--caacc";
export var column_1 = "portfolio-module--column_1--d3895";
export var column_2 = "portfolio-module--column_2--c4ecb";
export var column_3 = "portfolio-module--column_3--97718";
export var column_4 = "portfolio-module--column_4--84a51";
export var column_5 = "portfolio-module--column_5--83eb7";
export var column_6 = "portfolio-module--column_6--7f409";
export var columnfive = "portfolio-module--columnfive--553c4";
export var columnfour = "portfolio-module--columnfour--bb1e3";
export var columnone = "portfolio-module--columnone--e1c67";
export var columnsix = "portfolio-module--columnsix--1a20b";
export var columnthree = "portfolio-module--columnthree--588cb";
export var columntwo = "portfolio-module--columntwo--32f94";
export var coral = "#cb5e65";
export var coverImgs = "portfolio-module--coverImgs--16c09";
export var defaultBorder = "10px";
export var description = "portfolio-module--description--f5071";
export var forest = "#799884";
export var imageGrid = "portfolio-module--imageGrid--eb6e3";
export var imageGridWrap = "portfolio-module--imageGridWrap--240ea";
export var imageWrapper = "portfolio-module--imageWrapper--10a3e";
export var jetBlack = "#14171c";
export var layoutSection = "portfolio-module--layoutSection--dd4f1";
export var lightBase = "getColor(lightBase)";
export var lightdarkBase = "getColor(lightdarkBase)";
export var masOscuro = "#171926";
export var message = "portfolio-module--message--d3e4f";
export var midnight = "#383e5f";
export var minimal = "#f4f4f8";
export var mist = "#b8c3d0";
export var mobileWidth = "760px";
export var onyx = "#191c21";
export var oscuro = "#2d3346";
export var purple = "#9264e9";
export var raven = "#1e2329";
export var responsiveColumn_1 = "portfolio-module--responsiveColumn_1--48977";
export var responsiveColumn_2 = "portfolio-module--responsiveColumn_2--913cd";
export var responsiveColumn_3 = "portfolio-module--responsiveColumn_3--1a002";
export var steelBlue = "#2b313c";
export var subtexts = "portfolio-module--subtexts--cfd7e";
export var text = "portfolio-module--text--8fdc6";
export var textSection = "portfolio-module--textSection--1ba1f";
export var twoColumn = "portfolio-module--twoColumn--f74c4";
export var twoColumn_1to2 = "portfolio-module--twoColumn_1to2--1cdf3";
export var twoColumn_2to1 = "portfolio-module--twoColumn_2to1--13988";